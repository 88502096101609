import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LanguageSelectorComponent } from '@libs/shared/ui/shared/language-selector/language-selector.component';
import { BackupSrcDirective } from '@libs/shared/utilities/directives/backup-src/backup-src.directive';
import { PaymentBrandingService } from '../../services/payment-branding/payment-branding.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'kp-brand-wrapper',
  standalone: true,
  imports: [NgOptimizedImage, BackupSrcDirective, NgClass, TranslateModule, FormsModule, LanguageSelectorComponent],
  templateUrl: './brand-wrapper.component.html',
  styleUrls: ['./brand-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandWrapperComponent {
  constructor(private paymentBrandingService: PaymentBrandingService) {}

  @Input() supportsLanguage: boolean | null = null;
  @Input() language?: string | null;
  @Output() languageChange = new EventEmitter<string>();

  readonly fallbackLanguage = 'en';
  poweredByLogoSrc = 'global/images/kody.svg';
  poweredByLogoElement = `<img src="${this.poweredByLogoSrc}" height="22" />`;
  formAlignment = this.paymentBrandingService.formAlignment;
  brandLogo = this.paymentBrandingService.logo;
}
