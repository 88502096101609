import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NotificationsService } from '../notifications.service';

@Injectable({
  providedIn: 'root',
})
export class WebNotificationsService extends NotificationsService {

  readonly options: MatSnackBarConfig = {
    horizontalPosition: 'end',
    verticalPosition: 'top',
    duration: 2500,
  };

  constructor(private snackbar: MatSnackBar) {
    super();
  }

  private isLocked = false;

  override success(message: string, willLock = false): void {
    if (!this.isLocked || willLock) {
      this.isLocked = willLock;
      const options = {
        ...this.options,
        panelClass: ['mat-snack-bar-container--success'],
        duration: willLock ? undefined : this.options.duration,
      };
      this.snackbar.open(message, undefined, options);
    }
  }

  override error(message: string, willLock = false): void {
    if (!this.isLocked || willLock) {
      this.isLocked = willLock;
      const options = {
        ...this.options,
        panelClass: ['mat-snack-bar-container--error'],
        duration: willLock ? undefined : this.options.duration,
      };
      this.snackbar.open(message, undefined, options);
    }
  }

  override dismiss(): void {
    this.isLocked = false;
    this.snackbar.dismiss();
  }

  override pending(message: string): void | Promise<void> {
    const options = {
      ...this.options,
      panelClass: ['mat-snack-bar-container--pending'],
      duration: undefined,
    };
    this.snackbar.open(message, undefined, options);
  }
}
