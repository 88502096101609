export const kodyuniverseRoutes = {
  root: 'app',
  login: 'login',
  mfa: 'mfa',
  logout: 'logout',
  unauthorised: 'unauthorised',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  changePassword: 'change-password',
  newPassword: 'new-password',
  profile: 'profile',
  settings: 'settings',
  wallet: 'wallet',
  paymentLinks: 'payment-links',
  paymentLinksList: 'list',
  paymentLinksNew: 'new',
  settlements: 'settlements',
  transactions: 'transactions',
  statements: 'statements',
  deposits: 'pre-auths',
  payments: 'payments',
  batchPayments: 'batch-payments',
  insights: 'insights',
  loyalty: 'loyalty',
  membership: 'membership',
};
