import { Environment } from './environment.type';

export const environment: Environment = {
  name: 'development',
  production: false,
  appPrefix: 'wombat-web',
  adyen: {
    clientKey: 'test_SQ6WSUB5GFGNJFPOFXNNZ4V2E4EAX3PS',
    environment: 'test',
  },
  endpoints: {
    wombat: 'https://links.dev.kody.services',
    brandAssets: 'https://l-dev.kodypay.com/brands',
  },
  datadog: {
    applicationId: '25c61045-59a8-49b7-b485-a1c574546abb',
    clientToken: 'pubeae3bb0141f3165e054d1f6ae6c980b5',
    site: 'datadoghq.eu',
    environmentName: 'development',
    service: 'kp-wombat',
  },
  launchDarkly: {
    clientId: '62d80d1a2e3d8411b2d225c9',
  },
  googlePay: {
    countryCode: 'GB',
    environment: 'TEST',
    gatewayMerchantId: 'KodyPay_ECOM_BP',
    merchantId: 'BCR2DN4TU3J65FR4',
  },
};
