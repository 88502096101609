export const featureFlags = {
  universeKodyCardMfe: 'Universe_KodyCard_MFE',
  sendMoney: 'PP-460_send_money',
  recurringWithdrawals: 'KU-218_recurring-withdrawals',
  settlements: 'RK-12_settlements',
  domesticTransfers: 'pp-812-domestic-transfers',
  kodyAppCustomEventMonitoring: 'kill-switch-disable-kody-app-custom-event-monitoring',
  kuWebCustomEventMonitoring: 'kill-switch-disable-ku-web-custom-event-monitoring',
  wombatWebCustomEventMonitoring: 'kill-switch-disable-wombat-web-custom-event-monitoring',
  integratedPaymentsAppCustomEventMonitoring: 'kill-switch-disable-integrated-payments-app-custom-event-monitoring',
  payByBankAppCustomEventMonitoring: 'kill-switch-disable-pay-by-bank-app-custom-event-monitoring',
  analytics: 'PP-845_analytics',
  kodyAppHomeSalesChart: 'KA-69-Home-Sales-Chart',
  attachmentUpload: 'PP-749-Attachments',
  pinRevealV2: 'KU-324-pin_reveal_v2',
  enableLanguageChange: 'PP-926_pbl_locale',
  nativeSettlements: 'ka-121-native-settlements-feature',
  deposits: 'KU-335_deposits',
  payByLinkDeposits: 'GE-63-PBL-Deposits',
  payByLinkSaveCustomerInfo: 'GE-64-PBL-Save-Customer-Information',
  payByLinkSaveNotificationSettings: 'GE-64-PBL-Save-Notification-Settings',
  refundTransactions: 'KU-354-Refund-Transactions',
  insights: 'KU-390-customer-insights',
  insightsQuartilePointsChart: 'sos-59-quartile-points-chart',
  loyalty: 'SOS-33-Loyalty',
};
