import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureFlagValue } from '../../types/feature-flag-value.type';
import { FeatureFlagsState, featureFlagsStoreKey } from '../models/feature-flags-state.model';

const selectFeatureFlagsState = createFeatureSelector<FeatureFlagsState>(featureFlagsStoreKey);

const isActive = (key: string, expectedValue: FeatureFlagValue = true) =>
  createSelector(selectFeatureFlagsState, (state) => {
    if (!key) return true;
    const value = state?.flags[key];
    return value === expectedValue;
  });

const getActive = createSelector(selectFeatureFlagsState, (state) => {
  return Object.keys(state.flags).filter((key) => !!state.flags[key]);
});

const initialized = createSelector(selectFeatureFlagsState, (state) => state?.initialized);

export const featureFlagsSelectors = {
  isActive,
  getActive,
  initialized,
};
