export const kodycardRoutes = {
  featureRoot: '/app/cards',
  root: 'cards',
  login: 'login',
  unauthorised: 'unauthorised',
  listRoot: 'list',
  cardList: 'cards',
  accountList: 'accounts',
  statementList: 'statements',
  cardDetails: ':cardId',
  accountDetails: 'account',
  threeDs: '3ds',
};
