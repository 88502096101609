import { Inject, Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { storageKeys } from '@libs/shared/constants';
import { FeatureFlagFacadeService } from '@libs/utility-feature-flags/services/feature-flag-facade/feature-flag-facade.service';
import { APP_STORAGE, AppStorage } from '@libs/utility-storage';
import { lastValueFrom, take } from 'rxjs';
import { LOGGING_FEATURE_FLAG } from '../../tokens/logging-feature-flag.token';
import { SESSION_ID } from '../../../tokens/session-id.token';
import { APP_ENVIRONMENT } from '@libs/core/utilities';
import { Environment } from '@libs/core/types';
import { WINDOW } from '@libs/shared/utilities/tokens/window.token';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(
    private featureFlagFacadeService: FeatureFlagFacadeService,
    @Inject(APP_STORAGE) private appStorage: AppStorage,
    @Inject(LOGGING_FEATURE_FLAG) private loggingFeatureFlag: string,
    @Inject(SESSION_ID) private sessionId: string,
    @Inject(APP_ENVIRONMENT) private appEnvironment: Environment,
    @Inject(WINDOW) private window: Window,
  ) {}

  async logEvent(eventName: string, eventPayload = {}): Promise<void> {
    try {
      const monitoringIsActive = await lastValueFrom(this.featureFlagFacadeService.isActive(this.loggingFeatureFlag).pipe(take(1)));
      if (monitoringIsActive) {
        const userId = (await this.appStorage.getItem(storageKeys.userId)).value;
        const traceId = `${this.appEnvironment.appPrefix}-${this.window.crypto.randomUUID()}`;

        eventPayload = {
          ...eventPayload,
          tags: {
            kp: {
              sessionId: this.sessionId ?? '',
              userId: userId ?? '',
              traceId: traceId ?? '',
            },
          },
        };
        datadogLogs.logger.info(eventName, eventPayload);
      }
    } catch (err) {
      console.error(err);
    }
  }
}
