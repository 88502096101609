import { provideRouter, TitleStrategy, withEnabledBlockingInitialNavigation, withRouterConfig } from '@angular/router';
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { GRPC_LINK_FRONTEND_SERVICE_CLIENT_SETTINGS } from '@libs/core/grpc/com/kodypay/grpc/wombat/v1/link-frontend';
import { APP_INITIALIZER, importProvidersFrom, ApplicationConfig } from '@angular/core';
import {
  ADYEN_CLIENT_KEY,
  ADYEN_ENVIRONMENT,
  AdyenPaymentApiService,
  FeaturePaymentsModule,
  GOOGLE_PAY_CONFIG,
} from '@libs/feature-payments';
import { provideHttpClient } from '@angular/common/http';
import packageJson from '../../package.json';
import { shopperInformationSyncReducer } from '@libs/app-kodypayments/static/+state/reducers/meta/shopper-information-sync.reducer';
import { providePaymentFeature } from '@libs/app-kodypayments/static/+state/payment.feature';
import { BRAND_ASSET_ENDPOINT, PaymentBrandingService } from '@libs/feature-payments';
import { LinkPaymentApiService } from '@libs/app-kodypayments/static/services/link-payment-api/link-payment-api.service';
import {
  DATADOG_CONFIG,
  datadogErrorHandlingProviders,
} from '@libs/utility-monitoring/rum/providers/datadog-reporting-initializer/datadog-reporting-initializer.provider';
import { SharedGrpcModule } from '@libs/core/grpc/shared-grpc.module';
import { provideFeatureFlags } from '@libs/utility-feature-flags/+state/feature-flags-state.module';
import { LAUNCH_DARKLY_CLIENT_ID } from '@libs/utility-feature-flags/tokens/launch-darkly-client-id.token';
import { APP_ENVIRONMENT, PAGE_TITLE_SEPARATOR, RecursiveTitleStrategy } from '@libs/core/utilities';
import { TranslateService } from '@ngx-translate/core';
import { grpcRequestLoggingInterceptorProvider } from '@libs/utility-monitoring/interceptors/grpc-request-logging.interceptor';
import { APP_STORAGE, LocalStorageService } from '@libs/utility-storage';
import { LOGGING_FEATURE_FLAG } from '@libs/utility-monitoring/logs/tokens/logging-feature-flag.token';
import { featureFlags } from '@libs/utility-feature-flags/constants/feature-flags.constant';
import { SESSION_ID } from '@libs/utility-monitoring/tokens/session-id.token';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: APP_ENVIRONMENT, useValue: environment },
    {
      provide: DATADOG_CONFIG,
      useValue: {
        ...environment.datadog,
        version: packageJson.version,
      },
    },
    ...datadogErrorHandlingProviders,
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withRouterConfig({ paramsInheritanceStrategy: 'always' })),

    { provide: LAUNCH_DARKLY_CLIENT_ID, useValue: environment.launchDarkly.clientId },
    {
      provide: GRPC_LINK_FRONTEND_SERVICE_CLIENT_SETTINGS,
      useValue: {
        host: environment.endpoints.wombat,
        format: 'binary',
        suppressCorsPreflight: true
      },
    },
    { provide: APP_STORAGE, useClass: LocalStorageService },
    { provide: LOGGING_FEATURE_FLAG, useValue: featureFlags.wombatWebCustomEventMonitoring },
    {
      provide: SESSION_ID,
      useValue: `wombat-web-${window.crypto.randomUUID()}`,
    },
    {
      provide: ADYEN_CLIENT_KEY,
      useValue: environment.adyen.clientKey,
    },
    {
      provide: ADYEN_ENVIRONMENT,
      useValue: environment.adyen.environment,
    },
    {
      provide: BRAND_ASSET_ENDPOINT,
      useValue: environment.endpoints.brandAssets,
    },
    {
      provide: GOOGLE_PAY_CONFIG,
      useValue: environment.googlePay,
    },

    importProvidersFrom(SharedGrpcModule, FeaturePaymentsModule),
    grpcRequestLoggingInterceptorProvider,
    provideStore(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
        metaReducers: [shopperInformationSyncReducer],
      },
    ),
    provideStoreDevtools({ connectInZone: true }),
    provideFeatureFlags(),
    providePaymentFeature(),
    provideHttpClient(),

    {
      provide: APP_INITIALIZER,
      useFactory: (PaymentBrandingService: PaymentBrandingService) => () => PaymentBrandingService.initializeBranding(),
      deps: [PaymentBrandingService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translateService: TranslateService) => () => translateService.addLangs(['en', 'zh']),
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: AdyenPaymentApiService,
      useExisting: LinkPaymentApiService,
    },
    {
      provide: PAGE_TITLE_SEPARATOR,
      useValue: ' | ',
    },
    {
      provide: TitleStrategy,
      useExisting: RecursiveTitleStrategy,
    },
  ],
};
