import { Inject, Injectable } from '@angular/core';
import { GrpcEvent, GrpcMessage, GrpcRequest } from '@ngx-grpc/common';
import { GrpcHandler, GrpcInterceptor, GRPC_INTERCEPTORS } from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import { LoggingService } from '../logs/services/logging/logging.service';
import { SESSION_ID } from '../tokens/session-id.token';
import { WINDOW } from '@libs/shared/utilities/tokens/window.token';
import { APP_ENVIRONMENT } from '@libs/core/utilities';
import { Environment } from '@libs/core/types';

@Injectable()
export class GrpcRequestLoggingInterceptor implements GrpcInterceptor {
  constructor(
    private loggingService: LoggingService,
    @Inject(SESSION_ID) private sessionId: string,
    @Inject(WINDOW) private window: Window,
    @Inject(APP_ENVIRONMENT) private appEnvironment: Environment,
  ) {}
  intercept<Q extends GrpcMessage, S extends GrpcMessage>(request: GrpcRequest<Q, S>, next: GrpcHandler): Observable<GrpcEvent<S>> {
    const traceId = `${this.appEnvironment.appPrefix}-${this.window.crypto.randomUUID()}`;
    request.requestMetadata.set('kp-session-id', this.sessionId);
    request.requestMetadata.set('kp-trace-id', traceId);

    const eventPayload = {
      tags: {
        kp: {
          traceId: traceId ?? '',
          sessionId: this.sessionId ?? '',
          path: request.path ?? '',
        },
      },
    };

    this.loggingService.logEvent(`GRPC Request - ${request.path}`, eventPayload);
    return next.handle(request);
  }
}

export const grpcRequestLoggingInterceptorProvider = [{ provide: GRPC_INTERCEPTORS, useClass: GrpcRequestLoggingInterceptor, multi: true }];
