import { Inject, Injectable, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Environment } from '@libs/core/types';
import { APP_ENVIRONMENT } from '../tokens/app-environment.token';
import { PAGE_TITLE_SEPARATOR } from '../tokens/page-title-separator.token';
import { TranslateService } from '@ngx-translate/core';
import { skip, tap } from 'rxjs';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  constructor(
    private title: Title,
    @Optional() private translateService: TranslateService,
    @Inject(APP_ENVIRONMENT) private environment: Environment,
    @Inject(PAGE_TITLE_SEPARATOR) private pageTitleSeparator: string,
  ) {
    super();
    this.translateService?.onLangChange
      .pipe(
        skip(1),
        tap(() => {
          this.updateTitle(this.currentState);
        }),
      )
      .subscribe();
  }

  private currentState: RouterStateSnapshot;

  override updateTitle(routerState: RouterStateSnapshot) {
    this.currentState = routerState;
    const pageTitle = this.buildTitle(routerState);
    const resolvedTitle = pageTitle ? this.translateService?.instant(pageTitle) || pageTitle : pageTitle;
    this.title.setTitle(`${resolvedTitle ? `${resolvedTitle}${this.pageTitleSeparator}` : ''}${this.environment.appTitle}`);
  }
}
