import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[kpBackupSrc]',
  standalone: true,
})
export class BackupSrcDirective {
  @Input() kpBackupSrc: string;

  private activeSrc: ActiveSrc = 'original';

  constructor(private elementRef: ElementRef) {}

  @HostListener('error')
  onError() {
    if (this.activeSrc === 'original') {
      this.elementRef.nativeElement.src = this.kpBackupSrc;
      this.activeSrc = 'backup';
    }
  }
}

type ActiveSrc = 'original' | 'backup';
