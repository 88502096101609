import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faLanguage } from '@fortawesome/pro-regular-svg-icons/faLanguage';

@Component({
  selector: 'kp-language-selector',
  standalone: true,
  imports: [FormsModule, FontAwesomeModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LanguageSelectorComponent,
      multi: true,
    },
  ],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent extends ControlValueAccessor<string | undefined> {
  languageCode: string | undefined;

  readonly icons = { faAngleDown, faLanguage };

  readonly languageEntries: { code: string; name: string }[] = [
    { code: 'en', name: 'English' },
    { code: 'zh', name: '繁體中文' },
  ];

  writeValue(value: string | undefined): void {
    this.languageCode = value;
  }

  handleChange(value: string): void {
    this.onChange?.(value);
  }
}
