import { Inject, Injectable } from '@angular/core';
import { SECURE_STORAGE } from '../../token/secure-storage.token';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { AppStorage } from '../../interfaces/app-storage.interface';

@Injectable({
  providedIn: 'root',
})
export class SecureStorageService implements AppStorage {
  constructor(@Inject(SECURE_STORAGE) private secureStorage: typeof SecureStoragePlugin) {}

  async getItem(key: string): Promise<{ value: string | null }> {
    try {
      const keys = (await this.getKeys())?.value;

      if (keys && keys.includes(key)) {
        const result = await this.secureStorage.get({ key });

        const value = result?.value ?? null;

        return { value };
      } else {
        return { value: null };
      }
    } catch (error) {
      return { value: null };
    }
  }

  setItem(key: string, value: string): Promise<{ value: boolean }> {
    return this.secureStorage.set({ key, value });
  }

  async removeItem(key: string): Promise<{ value: boolean } | undefined> {
    const keys = (await this.getKeys())?.value;
    if (keys.includes(key)) {
      try {
        await this.secureStorage.remove({ key });
        return { value: true };
      } catch (error) {
        return { value: false };
      }
    }
    return;
  }

  getKeys(): Promise<{ value: string[] }> {
    return this.secureStorage.keys();
  }

  clear(): Promise<{ value: boolean }> {
    return this.secureStorage.clear();
  }

  async clearAllExcept(whitelistedKeys: string[] = []): Promise<{ value: boolean }> {
    const allKeys = (await this.getKeys()).value;

    if (!allKeys.length) {
      return { value: true };
    }

    const removableKeys = allKeys.filter((key) => !whitelistedKeys?.includes(key));

    if (!removableKeys.length) {
      return { value: true };
    }

    // Use Promise.allSettled to handle both successful and failed removals
    await Promise.allSettled(removableKeys.map((key) => this.removeItem(key)));

    return { value: true };
  }

  // returns which implementation is used - one of 'web', 'ios' or 'android'
  getPlatform(): Promise<{ value: string }> {
    return this.secureStorage.getPlatform();
  }

  async hasKey(key: string): Promise<boolean> {
    const allKeys = (await this.getKeys()).value;
    return allKeys.includes(key);
  }
}
