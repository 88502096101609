import { PermissionSubject, ServiceName } from '@libs/shared/types';

export const serviceNamesByPermissionSubject: Partial<Record<PermissionSubject, ServiceName>> = {
  'KodyUniverse': 'KodyUniverse',
  'KodyUniverse.KodyCard': 'KodyWallet',
  'KodyUniverse.PaymentTransactions': 'Sales',
  'KodyUniverse.Settlements': 'Settlements',
  'KodyUniverse.PayByLink': 'Payment Links',
  'KodyUniverse.Invoices': 'Statements',
  'KodyUniverse.Deposits': 'Pre-Auths',
};
