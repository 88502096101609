export interface AppRoutes {
  root: string;
  login: string;
  wallet: string;
  mfa: string;
  logout: string;
  settings: string;
  unauthorised: string;
  newPassword: string;
  fallbackRoute: string;
  noAccess: string;
  loggedOut: string;

  // TODO: refactor where the below routes are used to remove these
  enableBiometrics: string | undefined;
}
