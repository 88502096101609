import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AppStorage } from '../../interfaces/app-storage.interface';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements Partial<AppStorage> {
  private storage: Storage;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.storage = (this.document.defaultView as Window).localStorage;
  }

  getItem(key: string): Promise<{ value: string | null }> {
    return new Promise((resolve, reject) => {
      try {
        const value = this.storage.getItem(key);
        resolve({ value });
      } catch (error) {
        reject(error);
      }
    });
  }

  setItem(key: string, value: string): Promise<{ value: boolean }> {
    return new Promise((resolve, reject) => {
      try {
        this.storage.setItem(key, value);
        resolve({ value: true });
      } catch (error) {
        reject(error);
      }
    });
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): Promise<{ value: boolean }> {
    return new Promise((resolve, reject) => {
      try {
        this.storage.clear();
        resolve({ value: true });
      } catch (error) {
        reject(error);
      }
    });
  }

  clearAllExcept(whitelistedKeys: string[] = []): Promise<{ value: boolean }> {
    return new Promise((resolve, reject) => {
      try {
        const whitelistedValueMap = whitelistedKeys.map((key) => ({
          key,
          value: this.storage.getItem(key),
        }));
        this.storage.clear();
        whitelistedValueMap
          .filter(({ value }) => typeof value === 'string')
          .forEach(({ key, value }) => {
            this.storage.setItem(key, value as string);
          });
        resolve({ value: true });
      } catch (error) {
        reject(error);
      }
    });
  }

  async hasKey(key: string): Promise<boolean> {
    const { value } = await this.getItem(key);
    return new Promise((resolve, reject) => {
      try {
        if (value !== null) {
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}
