<div class="content" [ngClass]="'content--' + formAlignment">
  <main class="card">
    @if (language && supportsLanguage) {
      <kp-language-selector
        class="card__language-selector"
        [ngModel]="language"
        (ngModelChange)="languageChange.emit($event)"
      ></kp-language-selector>
    }
    <img class="card__logo" alt="" [src]="brandLogo" [kpBackupSrc]="'global/images/kody.svg'" />
    <ng-content></ng-content>
  </main>
  @if (supportsLanguage) {
    @if (language) {
      <div
        class="powered-by"
        [ngClass]="'powered-by--' + poweredByStyle"
        [innerHTML]="'common.poweredBy' | translate: { logo: poweredByLogoElement }"
      ></div>
    }
  } @else {
    <div class="powered-by" [ngClass]="'powered-by--' + poweredByStyle">
      <span>Powered by</span>
      <img [src]="'global/images/kody' + this.poweredByLogoSuffix + '.svg'" height="22" />
    </div>
  }
</div>
