
export abstract class NotificationsService implements AppNotifications {
  abstract success(message: string): void | Promise<void>;
  abstract error(message: string): void | Promise<void>;
  abstract pending(message: string): void | Promise<void>;
  abstract dismiss(): void;
}

export interface AppNotifications {
  success: (message: string) => void | Promise<void>;
  error: (message: string) => void | Promise<void>;
  pending(message: string): void | Promise<void>;
  dismiss: () => void;
}
