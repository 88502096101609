import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { featureFlagsSelectors } from '../../+state/selectors/feature-flags.selectors';
import { FeatureFlagValue } from '../../types/feature-flag-value.type';
import { StoreFacade } from '@libs/shared/utilities/classes/store-facade.class';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagFacadeService extends StoreFacade {
  constructor(protected override store: Store) {
    super(store);
  }
  isActive = (key: string, expectedValue: FeatureFlagValue = true) => this.store.select(featureFlagsSelectors.isActive(key, expectedValue));
  getActiveFlags = () => this.store.select(featureFlagsSelectors.getActive);
  initialized = () => this.store.select(featureFlagsSelectors.initialized);
}
