export const kodyuniverseMobileRoutes = {
  root: 'app',
  home: 'home',
  login: 'login',
  unauthorised: 'login',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  changePassword: 'change-password',
  enableBiometrics: 'enable-biometrics',
  settings: 'settings',
  sales: 'sales',
  noAccess: 'no-access',
  loggedOut: 'logged-out',
  accountTransaction: 'transactions',
  profile: 'profile',
  settlements: 'settlements',
  statements: 'statements',
};
